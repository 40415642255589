import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box } from '@mui/system';

// Assuming you have your logo imported
import Logo from './../../assets/logo.png';
import {alpha} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';

const Header = () => {
    const isTablet = useMediaQuery('(max-width:768px)');
    return (
        <AppBar position="sticky" sx={{ backgroundColor: alpha('#ffffff', 0.8) }} elevation={0} >

            <Toolbar sx={{ justifyContent: isTablet ? 'center' : 'space-between' }}>

                <Box className="app-bar-logo"
                     component="img"
                     sx={{
                         height: 64, // You can adjust the size as needed
                     }}
                     alt="Your Logo"
                     src={Logo}
                />

                {/* You can add more navigation items or buttons here */}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
