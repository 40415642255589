import React from 'react';
import Header from './components/Header';
import Slider from './components/Slider';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import './App.css';

const App: React.FC = () => {
    return (
        <div className="App">
            <Header />
            <Slider />
            <ContactUs />
            <Footer />
        </div>
    );
}

export default App;
