import React from 'react';
import {Box, Typography} from '@mui/material';
import TextTransition, {presets} from "react-text-transition";





const Slider = () => {


    return (
        <div><Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                padding: '40px 30px',
                backgroundColor: 'transparent', // Use color from your theme or design
                color: '#000000',
                fontWeight: 'bold'
            }}>
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                The future is now
            </Typography>

                <TextCarousel
                    TEXTS={

                    ['Soluzioni innovative',
                        'Team giovane e dinamico',
                        'Sviluppo software',
                        'Sviluppo app',
                        'Sviluppo web',
                        'Digitalizzazione aziendale',
                        'Consulenza informatica',
                        'Sicurezza informatica',
                        'Cloud computing',
                        'Intelligenza artificiale',
                        'Machine learning',
                        'Blockchain',
                        'Innovazione aggressiva',]
                    }/>

        </Box></div>   );
};

export default Slider;

// @ts-ignore
const TextCarousel = ({ TEXTS }) => {

    const [index, setIndex] = React.useState(0);

    React.useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            3000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);

    return (
        <h1>
            <TextTransition springConfig={presets.molasses} direction={"down"} >
                <Typography sx={{fontWeight: 'bold',color:'ActiveBorder'}} variant="h5">
                    {TEXTS[index % TEXTS.length]}
                </Typography>

            </TextTransition>
        </h1>
    );
};
