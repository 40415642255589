import React from 'react';
import './styles.module.css';

const Footer: React.FC = () => {
    return (
        <footer>
            {/* Your footer content here */}
            <p>© 2024 Our Company.</p>
        </footer>
    );
};

export default Footer;
