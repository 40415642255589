import React from 'react';
import './styles.module.css';

const ContactUs: React.FC = () => {
    return (
        <div className="contact-us">
            {/* Your contact form or information here */}
            <p>Contact Us</p>
        </div>
    );
};

export default ContactUs;
